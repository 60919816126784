/** Layouts */
import { Section, SectionTitle, SectionContent } from '../Section';
import Container from '../Container';

/** Components */
import RegistrationModal from '../../RegistrationModal';
import logo from '../../../images/logo-boostcamp-discovery.svg';

/**
 * <Cta />
 */

const Cta = (props) => {
  return (
    <Section className="cta">
      <Container layout="narrow">
        <SectionContent>
          <SectionTitle>
            <img src={logo} alt="Boostcamp Discovery" />
          </SectionTitle>
          <hr />
          <p>
            Goûtez dès maintenant à l’expérience pédagogique proposée par les écoles du Groupe OMNES Education&nbsp;!
          </p>
          <RegistrationModal cta="Inscrivez-vous gratuitement" buttonProps={{ layout: 'plain', color: 'secondary' }} />
        </SectionContent>
      </Container>
    </Section>
  );
};

export default Cta;
