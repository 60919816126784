import PropTypes from 'prop-types';
import { useRef, useState, useMemo, useCallback, useImperativeHandle, memo, forwardRef, Fragment } from 'react';

/** Hooks */
import { useRecaptcha } from '../../hooks/useRecaptcha';
import { useCsrfToken } from '../../hooks/useCsrfToken';
import { useFormValidation } from '../../hooks/useFormValidation';

/** Utils */
import { FormSender } from '../../util/Fetch';

/** Components */
import { TextField, TextareaField, SelectField } from './fields/Field';
import { Checkbox } from './fields/Checkbox';
import Button from '../Button';
import Link from '../Link';

/**
 * <Form />
 */

const Form = forwardRef(
  ({ type, action, autoComplete, label, buttonLabel, fields, children, isDisabled, ...props }, forwardedRef) => {
    /** CSRF Token & reCAPTCHA */
    const csrfToken = useCsrfToken(`post-form-${type}`);
    const recaptchaKey = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY;
    const loadRecaptcha = useRecaptcha(recaptchaKey);

    /** Form */
    const formRef = useRef();
    const formProps = useMemo(
      () => ({
        action: `${process.env.REACT_APP_API_URL}${action}`,
        method: 'POST',
        autoComplete: autoComplete ? 'on' : 'off',
        noValidate: true,
        onFocus: loadRecaptcha,
      }),
      [action, autoComplete, loadRecaptcha]
    );
    const customFormData = useMemo(() => new FormData(), []);

    /** Validation */
    const validateForm = useFormValidation(fields, action);
    const defaultMessages = useMemo(() => ({ errors: '', status: '', form: '', sent: '' }), []);
    const [messages, setMessages] = useState(defaultMessages);
    const [isSending, setIsSending] = useState(false);
    const [isSent, setIsSent] = useState(false);

    /** Redirection */
    const { redirectMessage } = props;
    const redirectUrl = useRef();
    const redirectDelay = useRef(5); // delay, in seconds
    const [redirectDelayState, setRedirectDelayState] = useState(redirectDelay.current);

    /**
     * Display an error message from the api or a default message
     *
     * @param {object} response  The response trom the api
     * @param {string} defaultMessage  The default message
     */
    const setSenderError = useCallback(
      (response, defaultMessage) => {
        setMessages({
          ...defaultMessages,
          form: response.data ? response.data.message : response.error ?? defaultMessage,
        });
      },
      [defaultMessages]
    );

    /**
     * Process the form sender response
     *
     * @param {JSON} response  The form sender response
     */
    const onSenderResponse = useCallback(
      (response) => {
        const { sent, data } = response;
        setIsSending(false);
        props.onResponse(sent && (data.isSent || data.status === 200));
        if (sent) {
          if (data.isSent) {
            setMessages({ ...defaultMessages, sent: 'Message envoyé' });
          } else if (data.status === 200) {
            setMessages({ ...defaultMessages, sent: response.data.message });
          }
          if (data.isSent || data.status === 200) {
            setIsSent(true);
            props.onSent(data);

            if (data.connection_url) {
              redirectUrl.current = data.connection_url;
              const intervalId = setInterval(() => {
                if (redirectDelay.current - 1 <= 0) {
                  if (process.env.NODE_ENV !== 'development') {
                    window.open(redirectUrl.current, '_self');
                  }
                  clearInterval(intervalId);
                }
                redirectDelay.current -= 1;
                setRedirectDelayState(redirectDelay.current);
              }, 1000);
            }
          }
        }
        if (!sent || !(data.isSent || data.status === 200)) {
          setSenderError(response, 'Erreur lors de l’envoi');
        }
      },
      [defaultMessages, props, redirectDelay, setSenderError]
    );

    /**
     * Send the form
     *
     * @param {string} captchaToken  The reCAPTCHA token
     */
    const sendForm = useCallback(
      (captchaToken) => {
        /** Set the form data */
        const formData = Array.from(customFormData).length > 0 ? customFormData : new FormData(formRef.current);
        formData.append('type', type);
        formData.append('action', action.replace('.php', ''));

        /** Send the form */
        const sender = new FormSender(formData, formProps.action);
        csrfToken && sender.addHeader('X-CSRF-TOKEN', csrfToken);
        captchaToken && sender.addHeader('X-CAPTCHA-TOKEN', captchaToken);
        sender.addListener('response', (data) => onSenderResponse(data));
        sender.send();
        setIsSending(true);
        props.onSend();

        /** Set the status message */
        setMessages({ ...defaultMessages, status: 'Envoi en cours' });
      },
      [action, csrfToken, customFormData, defaultMessages, formProps.action, formRef, onSenderResponse, props, type]
    );

    /**
     * Submit the form
     *
     * Validate the form, Check Google reCAPTCHA and then send the form
     *
     * @param {SubmitEvent} event  The form submit event
     */
    const submitForm = useCallback(
      (event) => {
        event.preventDefault();
        const [isValid, errors] = validateForm(formRef.current);
        setMessages({ ...defaultMessages, errors, form: isValid ? '' : 'Le formulaire contient des erreurs' });

        if (isValid) {
          window.grecaptcha.ready(() =>
            window.grecaptcha
              .execute(recaptchaKey, { action: action.replace('.php', '') })
              .then((token) => sendForm(token))
          );
        }
      },
      [action, defaultMessages, formRef, recaptchaKey, sendForm, validateForm]
    );

    /** Expose methods and the form status to the referrer */
    useImperativeHandle(
      forwardedRef,
      () => ({
        setMessages,
        defaultMessages,
        setFormData: (name, value) => customFormData.set(name, value),
        setFormFile: (name, value, fileName) => customFormData.set(name, value, fileName),
        submitForm,
        getIsSending: () => isSending,
        getIsSent: () => isSent,
        reset: () => {
          setIsSent(false);
          setMessages(defaultMessages);
          for (const key of customFormData.keys()) {
            customFormData.delete(key);
          }
        },
      }),
      [defaultMessages, submitForm, customFormData, isSending, isSent]
    );

    return (
      <form
        ref={formRef}
        {...formProps}
        className={`form ${type}-form ${isDisabled ? 'is-disabled' : ''}`}
        onSubmit={(event) => (props.onSubmit ? props.onSubmit(event) : submitForm(event))}
      >
        <h3 className="visually-hidden">{label}</h3>
        {!isSent && fields
          ? fields.map((field, index) => (
              <Fragment key={index}>
                {['text', 'email', 'url', 'tel', 'password'].includes(field.type) ? (
                  <TextField
                    {...field}
                    errorMessage={messages.errors[field.name]}
                    isDisabled={isDisabled || isSent || isSending}
                  />
                ) : field.type === 'textarea' ? (
                  <TextareaField
                    {...field}
                    errorMessage={messages.errors[field.name]}
                    isDisabled={isDisabled || isSent || isSending}
                  />
                ) : field.type === 'checkbox' ? (
                  <Checkbox
                    {...field}
                    errorMessage={messages.errors[field.name]}
                    isDisabled={isDisabled || isSent || isSending}
                  >
                    {field.label}
                  </Checkbox>
                ) : field.type === 'select' ? (
                  <SelectField
                    {...field}
                    errorMessage={messages.errors[field.name]}
                    isDisabled={isDisabled || isSent || isSending}
                  />
                ) : (
                  <></>
                )}
              </Fragment>
            ))
          : children && <>{children}</>}

        {/** Cookie notice */}
        {!isSent && <FormCookieNotice />}

        {/** Messages */}
        {isSent && <p className="form-sent">{messages.sent}</p>}
        {messages.status && <p className="form-status">{messages.status}</p>}
        {messages.form && <p className="form-error">{messages.form}</p>}

        {/** Redirect the user */}
        {isSent && redirectUrl.current && redirectMessage && (
          <p className="form-redirect">
            {/* prettier-ignore */}
            <span dangerouslySetInnerHTML={{__html: redirectMessage.replace('%s', `<strong>${redirectDelayState}</strong>`)}} />
            <br />
            <Link url={redirectUrl.current} title="Cliquez ici pour être redirigé directement" />.
          </p>
        )}

        <div className={`form-submit ${isSent ? 'is-sent' : ''}`}>
          {!isSent && (
            <Button
              layout="plain"
              color="secondary"
              type="submit"
              className="submit-button"
              isDisabled={isDisabled || isSent || isSending}
            >
              {buttonLabel ? buttonLabel : 'Envoyer'}
            </Button>
          )}
        </div>
      </form>
    );
  }
);
Form.displayName = 'Form';

/**
 * You have to provide either
 * - the fields prop
 * - the children prop
 */
Form.propTypes = {
  /** The type of the form */
  type: PropTypes.oneOf(['registration']).isRequired,
  /** The app route to post the form to */
  action: PropTypes.oneOf(['create_user.php']).isRequired,
  /** Whether to use autocomplete for the form */
  autoComplete: PropTypes.bool,
  /** A label for the form */
  label: PropTypes.string.isRequired,
  /** The label of the button */
  buttonLabel: PropTypes.string,
  /** The fields of the form */
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      /** The name attribute */
      name: PropTypes.string.isRequired,
      /** The type of the field */
      type: PropTypes.oneOf(['text', 'email', 'url', 'tel', 'password', 'textarea', 'checkbox', 'select']).isRequired,
      /** A label for the field */
      label: PropTypes.string.isRequired,
      /** A placeholder for the field */
      placeholder: PropTypes.string,
      /** The max length of the field */
      maxLength: PropTypes.number,
      /** Whether the field is required */
      isRequired: PropTypes.bool,
    })
  ),
  /** The children of the element */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** Whether the form should be disabled */
  isDisabled: PropTypes.bool,
  /** A callback for the onSubmit event */
  onSubmit: PropTypes.func,
  /** A callback for the onSend event */
  onSend: PropTypes.func,
  /** A callback for the onResponse event */
  onResponse: PropTypes.func,
  /** A callback for the onSent event */
  onSent: PropTypes.func,
  /** The url to redirect the user on successful post */
  redirectUrl: PropTypes.string,
  /** The message to display with the countdown before redirecting, required if `redirectUrl` is provided */
  redirectMessage: PropTypes.string,
};

Form.defaultProps = {
  autoComplete: false,
  isDisabled: false,
  onSend: () => {},
  onResponse: () => {},
  onSent: () => {},
};

/**
 * <FormCookieNotice />
 */

const FormCookieNotice = (props) => {
  return (
    <div className="form-cookie-notice">
      <h6>Informations sur les cookies</h6>
      <p>
        Les formulaires de contact du site sont protégés par reCAPTCHA v3. La{' '}
        <Link url="https://www.google.com/policies/privacy/" target="_blank">
          Politique de confidentialité
        </Link>{' '}
        et les{' '}
        <Link url="https://www.google.com/policies/terms/" target="_blank">
          Conditions d’utilisation
        </Link>{' '}
        de Google sont applicables.
      </p>
    </div>
  );
};

export default memo(Form);
