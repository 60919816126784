import PropTypes from 'prop-types';
import { usePress } from 'react-aria';

/** Layouts */
import { Section, SectionTitle, SectionContent } from '../Section';
import Container from '../Container';

/** Components */
import Covers from '../../Covers';
import RegistrationForm from '../../forms/RegistrationForm';
import logo from '../../../images/logo-boostcamp-discovery.svg';

/**
 * <Registration />
 */

const Registration = (props) => {
  const { pressProps } = usePress({
    onPress: props.onClose,
  });

  return (
    <Section className="registration">
      <Container layout="narrow">
        <header className="section-header">
          <div className="logo">
            <img src={logo} alt="Boostcamp Discovery" {...pressProps} />
          </div>
          <SectionTitle>
            Créez votre compte
            <br />
            <span>gratuitement</span>
          </SectionTitle>
        </header>
        <SectionContent>
          <RegistrationForm />
        </SectionContent>
      </Container>
      <div>
        <Covers className="registration-covers" />
      </div>
    </Section>
  );
};

Registration.propTypes = {
  /** A callback for the onClose event */
  onClose: PropTypes.func,
};
Registration.defaultProps = {
  onClose: () => {},
};

export default Registration;
