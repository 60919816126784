import PropTypes from 'prop-types';
import { useState } from 'react';
import { usePress } from 'react-aria';

/** PropTypes */
import { commonFieldPropTypes } from './propTypes';

/** Hooks */
import { useMenu } from '../../../hooks/useMenu';
import { useUniqueId } from '../../../hooks/useUniqueId';

/** Components */
import RadioGroup from './RadioGroup';
import Menu from '../../Menu';
import Button from '../../Button';

/**
 * <Select />
 */

const Select = (props) => {
  const { id, name, label, placeholder, isRequired, isDisabled, values, logos, value: initialState } = props;

  const legendId = useUniqueId();
  const [value, setValue] = useState(initialState);

  /** Menu refs, state and props */
  const { menuTriggerRef, menuTriggerProps, menuRef, menuState, menuProps } = useMenu(props);
  const { pressProps } = usePress({
    onPress: () => !isDisabled && menuState.open(),
  });

  return (
    <div className="select" {...{ id }}>
      <Button ref={menuTriggerRef} {...menuTriggerProps} className="select-button label">
        {label}
      </Button>

      {menuState.isOpen && (
        <Menu ref={menuRef} {...menuProps} position="left">
          <fieldset className="field-overlay">
            <legend id={legendId} className="visually-hidden">
              {label}
            </legend>
            <RadioGroup
              className="select-radio-group"
              radios={values}
              value={value}
              logos={logos}
              aria-labelledby={legendId}
              onChange={(value) => {
                setValue(value);
                menuRef.current.close();
              }}
            />
          </fieldset>
        </Menu>
      )}

      <input
        type="text"
        className={`select-value ${isRequired ? 'is-required' : ''} ${isDisabled ? 'is-disabled' : ''}`}
        {...pressProps}
        {...{ required: isRequired || null, disabled: isDisabled || null }}
        name={name}
        value={value}
        placeholder={placeholder}
        readOnly
        aria-disabled
      />
    </div>
  );
};

Select.propTypes = {
  ...commonFieldPropTypes,
  /** The values of the select */
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  /** Logos to use instead of radios labels */
  logos: PropTypes.arrayOf(PropTypes.string),
  /** If logos are used, whether to display the label text next to the image */
  displayLabel: PropTypes.bool,
};
Select.defaultProps = {
  value: '',
};

export default Select;
