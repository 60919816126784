import { useState, useEffect } from 'react';

/** Hooks */
import { useIsScrolled } from '../../hooks/useIsScrolled';
import { useOnResize } from '../../hooks/useOnResize';

/** Utils */
import { breakpointDown } from '../../util/Breakpoint';

/** Layouts */
import Container from './Container';

/** Components */
import logo from '../../images/logo-boostcamp-discovery.svg';
import Link from '../Link';
import Icon from '../Icon';
import RegistrationModal from '../RegistrationModal';

const loginUrl = 'https://discovery-boostcamp.omneseducation.com/theme/boostcamp/dashboard.php';

/**
 * <Header />
 */

const Header = (props) => {
  const isScrolled = useIsScrolled();

  /** Handle the `isMobile` State */
  const [isMobile, setIsMobile] = useState(window.matchMedia(breakpointDown('sm')).matches);
  const handleResize = () => setIsMobile(window.matchMedia(breakpointDown('sm')).matches);
  useOnResize(handleResize);
  useEffect(handleResize, []);
  const buttonLayout = isMobile || isScrolled ? 'plain' : 'outline';

  return (
    <header className={`layout header ${isScrolled ? 'is-scrolled' : ''}`} role="banner">
      <Container>
        <div className="baseline header-baseline">
          <h1 className="logo header-logo">
            <img src={logo} alt="Boostcamp Discovery" />
          </h1>
          {isMobile && (
            <Link url={loginUrl} target="_blank">
              <Icon icon="user" ariaLabel="Me connecter" />
            </Link>
          )}
        </div>
        <nav className="nav header-nav" aria-label="Menu principal">
          <ul className="nav-primary">
            {!isMobile && (
              <li>
                <Link url={loginUrl} target="_blank" title="Me connecter" />
              </li>
            )}
            <li>
              <RegistrationModal
                cta="Inscrivez-vous gratuitement"
                buttonProps={{ layout: buttonLayout, color: 'secondary' }}
              />
            </li>
          </ul>
        </nav>
      </Container>
    </header>
  );
};

export default Header;
