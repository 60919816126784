import CEDS from '../images/schools/CEDS.svg';
import CREA from '../images/schools/CREA.svg';
import ECE from '../images/schools/ECE.svg';
import ESCE from '../images/schools/ESCE.svg';
import EUBS from '../images/schools/EU-BS.svg';
import HEIP from '../images/schools/HEIP.svg';
import INSEEC from '../images/schools/INSEEC.svg';
import IUM from '../images/schools/IUM.svg';
import SUPCAREER from '../images/schools/SUPCAREER.svg';
import SUPdeCreation from '../images/schools/SUPdeCreation.svg';
import SupDePub from '../images/schools/SupDePub.svg';
import DataScientest from '../images/schools/DataScientest.svg';

/**
 * Don't forget to build the app if you change something into this array
 */
export const schoolsGrouped = [
  {
    category: 'Management',
    description: 'Nos écoles en Management',
    schools: [
      {
        name: 'ESCE - International Business School',
        url: 'https://www.omneseducation.com/nos-etablissements/nos-ecoles/esce/',
        referrer: ['https://www.esce.fr/', 'https://ecoles.esce.fr/'],
        logo: ESCE,
        shortname: 'esce',
      },
      {
        name: 'EU - Business School',
        url: 'https://www.omneseducation.com/nos-etablissements/nos-ecoles/eubs/',
        referrer: ['https://www.euruni.edu/'],
        logo: EUBS,
        shortname: 'eubs',
      },
      {
        name: 'INSEEC',
        url: 'https://www.omneseducation.com/nos-etablissements/nos-ecoles/inseec/',
        referrer: ['https://www.inseec.com/'],
        logo: INSEEC,
        shortname: 'inseec',
      },
      {
        name: 'INSEEC BTS',
        shortname: 'inseec_bts',
      },
      {
        name: 'INSEEC BACHELOR',
        shortname: 'inseec_bachelor',
      },
      {
        name: 'INSEEC BBA',
        shortname: 'inseec_bba',
      },
      {
        name: 'INSEEC Grande École',
        shortname: 'inseec_grande_ecole',
      },
      {
        name: 'INSEEC MSc',
        shortname: 'inseec_msc',
      },
      {
        name: 'IUM - International University of Monaco',
        url: 'https://www.omneseducation.com/nos-etablissements/nos-ecoles/ium-monaco/',
        referrer: ['https://www.monaco.edu/'],
        logo: IUM,
        shortname: 'ium',
      },
      {
        name: 'SUP Career Alternance',
        url: 'https://www.omneseducation.com/nos-etablissements/nos-ecoles/sup-career-alternance/',
        referrer: ['https://www.supcareer.com/', 'https://ecoles.supcareer.com/'],
        logo: SUPCAREER,
        shortname: 'supcareer',
      },
    ],
  },
  {
    category: 'Communication & Création',
    description: 'Nos écoles en Communication & Création',
    schools: [
      {
        name: 'CREA',
        url: 'https://www.omneseducation.com/nos-etablissements/nos-ecoles/crea/',
        referrer: ['https://www.creageneve.com/'],
        logo: CREA,
        shortname: 'crea',
      },
      {
        name: 'SUP de Creation',
        url: 'https://www.omneseducation.com/nos-etablissements/nos-ecoles/sup-de-creation/',
        referrer: ['https://www.supdecreation.com/'],
        logo: SUPdeCreation,
        shortname: 'supdecrea',
      },
      {
        name: 'Sup de Pub',
        url: 'https://www.omneseducation.com/nos-etablissements/nos-ecoles/sup-de-pub/',
        referrer: ['https://supdepub.com/', 'https://ecoles.supdepub.com/'],
        logo: SupDePub,
        shortname: 'supdepub',
      },
    ],
  },
  {
    category: 'Ingénierie',
    description: 'Nos écoles d’ingénieurs',
    schools: [
      {
        name: 'Data Scientest',
        url: 'https://www.omneseducation.com/nos-etablissements/datascientest/',
        referrer: ['https://datascientest.com/'],
        logo: DataScientest,
        shortname: 'datascientest',
      },
      {
        name: 'ECE - École d’Ingénieurs',
        url: 'https://www.omneseducation.com/nos-etablissements/nos-ecoles/ece/',
        referrer: ['https://www.ece.fr/', 'https://ecoles.ece.fr/'],
        logo: ECE,
        shortname: 'ece',
      },
    ],
  },
  {
    category: 'Sciences politiques & relations internationales',
    description: 'Nos écoles Sciences Po & Relations Internationales',
    schools: [
      {
        name: 'CEDS - Études Diplomatiques & Stratégiques',
        url: 'https://www.omneseducation.com/nos-etablissements/nos-ecoles/ceds/',
        referrer: ['https://www.ceds.fr/'],
        logo: CEDS,
        shortname: 'ceds',
      },
      {
        name: 'HEIP - Hautes Études Internationales & Politiques',
        url: 'https://www.omneseducation.com/nos-etablissements/nos-ecoles/heip/',
        referrer: ['https://heip.fr/'],
        logo: HEIP,
        shortname: 'heip',
      },
    ],
  },
];

/** The schools array, flattened */
export const schoolsFlattened = schoolsGrouped
  .reduce((previousValue, currentValue) => [...previousValue, ...currentValue.schools], [])
  .sort((a, b) => a.name.localeCompare(b.name));

export default schoolsGrouped;
