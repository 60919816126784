import PropTypes from 'prop-types';

/**
 * <Icon />
 */

const Icon = ({ icon, layout, ariaHidden, ariaLabel }) => {
  /** ARIA */
  const ariaProps = {
    'aria-hidden': (ariaHidden && !ariaLabel) || null,
    'aria-label': ariaLabel,
  };

  /** Class names */
  const classNames = [`icon icon-${icon}`];
  layout && classNames.push(`l-${layout}`);

  return <span {...ariaProps} className={classNames.join(' ')}></span>;
};

Icon.propTypes = {
  /** The name of the icon */
  icon: PropTypes.oneOf([
    'youtube',
    'y',
    'facebook',
    'f',
    'twitter',
    't',
    'x',
    'linkedin',
    'l',
    'instagram',
    'i',
    'clock',
    'layers',
    'play',
    'star',
    'password-hidden',
    'password-visible',
    'blank',
    'library',
    'om',
    'platform',
    'accent',
    'xmark',
    'gold-award',
    'user',
  ]).isRequired,
  /** The layout of the element */
  layout: PropTypes.oneOf(['round', 'square-outline']),
  /** Whether to hide the element for ARIA */
  ariaHidden: PropTypes.bool,
  /** The ARIA label to set to the element when using an icon as its child */
  ariaLabel: PropTypes.string,
};
Icon.defaultProps = {
  ariaHidden: true,
};

export default Icon;
