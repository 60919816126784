import PropTypes from 'prop-types';
import { forwardRef } from 'react';

/**
 * <Container />
 */

export const Container = forwardRef(({ id, className, children, layout }, containerRef) => (
  <div ref={containerRef} id={id} className={`container ${layout ? `l-${layout}` : ''} ${className}`}>
    {children}
  </div>
));

Container.displayName = 'Container';
Container.propTypes = {
  /** The id attribute of the element */
  id: PropTypes.string,
  /** The class names to add to the element */
  className: PropTypes.string,
  /** The children of the element */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  /** The layout to apply to the element */
  layout: PropTypes.oneOf(['narrow']),
};
Container.defaultProps = {
  className: '',
};

export default Container;
