import PropTypes from 'prop-types';
import { useRef, useEffect, useImperativeHandle, forwardRef, memo } from 'react';
import { useModalOverlay, Overlay, DismissButton } from 'react-aria';

/** PropTypes */
import { colorPropTypes } from '../propTypes';

/** Hooks */
import { useCloseWithTransition } from '../hooks/useCloseWithTransition';
import { useOnLoad } from '../hooks/useOnLoad';

/** Components */
import Button from './Button';

/**
 * <Modal />
 */

const Modal = forwardRef(({ state: modalState, className, children, color, ...props }, modalRef) => {
  const close = () => (props.onClose ? props.onClose() : modalState.close());

  const underlayRef = useRef();
  const overlayRef = useRef();
  const { overlayProps, underlayProps } = useModalOverlay(props, modalState, overlayRef);
  const closeWithTransition = useCloseWithTransition(underlayRef, close, 250);

  /** Close the overlay when pressing the Escape key */
  useEffect(() => {
    const keyPressHandler = (e) => e.key === 'Escape' && closeWithTransition();
    window.addEventListener('keyup', keyPressHandler);
    return () => window.removeEventListener('keyup', keyPressHandler);
  }, [closeWithTransition]);

  /** Expose a close method to the referrer */
  useImperativeHandle(modalRef, () => ({ close: () => closeWithTransition() }), [closeWithTransition]);

  /** Create a new history state and close the modal on "back" action */
  useEffect(() => {
    window.history.pushState({}, 'Modal', '');
    window.addEventListener('popstate', closeWithTransition);
    return () => window.removeEventListener('popstate', closeWithTransition);
  }, [closeWithTransition]);

  /** Fire a load Event */
  const onLoad = useOnLoad('modal-');
  useEffect(() => onLoad());

  /** ARIA */
  const ariaProps = {
    ...(props.id && { id: props.id }),
  };

  return (
    <Overlay>
      <div ref={underlayRef} {...underlayProps} {...ariaProps} className={`modal ${className}`}>
        <div>
          <div ref={overlayRef} {...overlayProps} className={`modal-content ${color ? `bg-${color}` : ''}`}>
            <Button className="modal-close-button" icon="xmark" onPress={closeWithTransition} ariaLabel="Fermer" />
            {children}
            <DismissButton onDismiss={close} />
          </div>
        </div>
      </div>
    </Overlay>
  );
});

Modal.displayName = 'Modal';

Modal.propTypes = {
  /** The id of the modal */
  id: PropTypes.string,
  /** The react-aria state of the element */
  state: PropTypes.object,
  /** The class names to add to the element */
  className: PropTypes.string,
  /** The children of the element */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  /** The color of the element */
  color: colorPropTypes,
  /** A callback for the onClose event */
  onClose: PropTypes.func,
};
Modal.defaultProps = {
  className: '',
};

export default memo(Modal);
