import { useRef, useState, useEffect } from 'react';

/** Hooks */
import { useOnResize } from '../../../hooks/useOnResize';
import { useKalturaPlayer } from '../../../hooks/useKalturaPlayer';

/** Layouts */
import { Section, SectionTitle, SectionContent } from '../Section';
import Container from '../Container';

/** Components */
import RegistrationModal from '../../RegistrationModal';
import Covers from '../../Covers';

/**
 * <Hero />
 */

const Hero = (props) => {
  const sectionRef = useRef();

  /** Kaltura Player */
  const loadKalturaPlayer = useKalturaPlayer();
  const videoProps = {
    partnerId: 353,
    uiConfId: 23454336,
    entryId: '0_xwxxtyme',
    targetId: 'kaltura_player_818193211',
  };
  loadKalturaPlayer(videoProps)
    .then(() => {
      try {
        const { partnerId, uiConfId, entryId, targetId } = videoProps;
        const kalturaPlayer = window.KalturaPlayer.setup({ targetId, provider: { partnerId, uiConfId } });
        kalturaPlayer.loadMedia({ entryId });
      } catch (e) {
        console.warn(e.message);
      }
      return;
    })
    .catch(console.warn);

  /** Update the value of the --hero-height css variable on resize */
  const [heroHeight, setHeroHeight] = useState(600); // Approximative height
  const handleResize = () => {
    const height = sectionRef.current.getBoundingClientRect().height;
    setHeroHeight(Math.round(height));
  };
  useOnResize(handleResize);
  useEffect(handleResize, []);

  return (
    <Section ref={sectionRef} className="hero bg-primary" tag="header" style={{ '--hero-height': `${heroHeight}px` }}>
      <Container layout="narrow">
        <SectionContent>
          <SectionTitle className="h1">Boostcamp Discovery</SectionTitle>
          <p>
            Goûtez dès maintenant à l’expérience pédagogique proposée par les écoles du Groupe OMNES Education&nbsp;!
          </p>
          <RegistrationModal cta="Inscrivez-vous gratuitement" buttonProps={{ layout: 'plain', color: 'secondary' }} />
        </SectionContent>
        <SectionContent>
          <div className="hero-video">
            <div id={videoProps.targetId} />
          </div>
          <Covers className="hero-covers" />
        </SectionContent>
      </Container>
    </Section>
  );
};

export default Hero;
