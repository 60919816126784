import PropTypes from 'prop-types';

/**********
 * FIELDS *
 **********/

/**
 * PropTypes common for all form fields
 */
export const commonFieldPropTypes = {
  /** An unique id to add to the field */
  id: PropTypes.string,
  /** The name of the field */
  name: PropTypes.string.isRequired,
  /** The label of the field */
  label: PropTypes.string.isRequired,
  /** The placeholder of the field */
  placeholder: PropTypes.string,
  /** The default value of the field */
  value: PropTypes.string,
  /** The description of the field */
  description: PropTypes.string,
  /** The error message of the field */
  errorMessage: PropTypes.string,
  /** Whether the input is disabled */
  isDisabled: PropTypes.bool,
  /** Whether the input can be selected but not changed by the user */
  isReadOnly: PropTypes.bool,
  /** Whether the input is required before form submission */
  isRequired: PropTypes.bool,
  /** Whether the input should display its "valid" or "invalid" visual styling */
  validationState: PropTypes.oneOf(['valid', 'invalid']),
  /** Whether the element should receive focus on render */
  autoFocus: PropTypes.bool,
};
