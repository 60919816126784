import PropTypes from 'prop-types';
import { useRef, forwardRef, useState, useEffect, useImperativeHandle } from 'react';

/** Hooks */
import { useUniqueId } from '../hooks/useUniqueId';

/**
 * <Collapsable />
 *
 * @param {boolean} isCollapsed  Whether the collapsable component should be initially collapsed
 * @param {object} buttonRef  A React Ref to the collapsable component's toggle button
 * @param {string} className  Additionnal class names to add to the collapsable component
 * @param {object|object[]} children  The collapsable component's children
 * @param {string} mq  A media query that enables the component only when matched
 * @returns {object}  The collapsable component
 */
const Collapsable = forwardRef(({ isCollapsed: initialState, className, children, mq, ...props }, ref) => {
  process.env.NODE_ENV === 'development' && console.info('<Collapsable />');

  const { buttonRef } = props;
  const collapsableRef = useRef();
  const collapsableId = useUniqueId();
  const height = useRef(0);
  const isCollapsed = useRef(initialState);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const collapsable = collapsableRef.current;
    const button = buttonRef.current;

    /**
     * Define the height of the element(s)
     */
    const defineHeights = () => {
      process.env.NODE_ENV === 'development' && console.info('<Collapsable /> - defineHeights');
      /** Show the element(s) to calculate the height */
      collapsable.classList.remove('is-collapsed');
      collapsable.style.height = 'auto';
      /** Update the height of the element(s) */
      height.current = collapsable.getBoundingClientRect().height;
      collapsable.style.height = `${height.current}px`;
      /** Recollapse the content if the overlay was closed */
      if (isCollapsed.current) {
        /** If a media query is provided, collapse only if that query matches */
        if (!mq || window.matchMedia(mq).matches) {
          collapsable.classList.add('is-collapsed');
        }
      }
    };

    /**
     * Resize handler
     *
     * @param {CustomEvent|Event} e  The resize event
     */
    const onResize = (e) => {
      if (!window.customResize || e.detail.width) {
        defineHeights();
      }
    };

    /**
     * Initialize
     */
    if (collapsable && button) {
      /** Set the html attributes */
      collapsable.id = collapsableId;
      button.classList.add('collapsable-button');
      button.setAttribute('aria-haspopup', 'true');
      button.setAttribute('aria-expanded', !isCollapsed.current);
      button.setAttribute('aria-controls', collapsableId);
      /** Bind the listeners */
      window.addEventListener(window.customResize ? 'custom-resize' : 'resize', onResize);
      /** Define the heights of the element(s) */
      defineHeights();
    }

    /**
     * Destroy
     */
    return () => {
      process.env.NODE_ENV === 'development' && console.info('<Collapsable /> - Destroy');
      /** Unbind the listeners & remove html attributes */
      window.removeEventListener(window.customResize ? 'custom-resize' : 'resize', onResize);
      button.classList.remove('collapsable-button');
      button.removeAttribute('aria-haspopup');
      button.removeAttribute('aria-expanded');
      button.removeAttribute('aria-controls');
      collapsable.classList.remove('is-collapsed');
    };
  }, [ref, buttonRef, collapsableRef, isCollapsed, mq, height, refresh, collapsableId]);

  /**
   * Update the height of the element(s)
   *
   * @param {number} value  The height to apply
   */
  const updateHeight = (value) => {
    height.current = value;
    collapsableRef.current.style.height = `${height.current}px`;
  };

  /**
   * Expose properties to the parent component
   */
  useImperativeHandle(ref, () => ({
    /**
     * Expose a ref to the current element
     */
    collapsable: collapsableRef.current,

    /**
     * Toggle the content visibility
     */
    toggle: () => {
      /** If a media query is provided, toggle only if that query matches */
      if (!mq || window.matchMedia(mq).matches) {
        isCollapsed.current = !isCollapsed.current;
        buttonRef.current.setAttribute('aria-expanded', !isCollapsed.current);
        collapsableRef.current.classList.toggle('is-collapsed');
      }
    },

    /**
     * Return whether the content is collapsed
     *
     * @returns {boolean}  Whether the content is collapsed
     */
    isCollapsed: () => {
      return isCollapsed.current;
    },

    /**
     * Return the height of the content element
     *
     * @returns {number}  The height of the content element
     */
    getHeight: () => {
      return height.current;
    },

    /**
     * Change the height of the element
     *
     * @param {number} value  The height to apply
     * @returns {Function}  The Update height method
     */
    setHeight: (value) => updateHeight(value),

    /**
     * Recalculate the height of the element
     */
    refresh: () => {
      process.env.NODE_ENV === 'development' && console.info('<Collapsable /> - Refresh');
      setRefresh(Math.random());
    },
  }));

  return (
    <div ref={collapsableRef} className={`collapsable ${className}`}>
      {children}
    </div>
  );
});

Collapsable.displayName = 'Collapsable';
Collapsable.propTypes = {
  // prettier-ignore
  buttonRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]).isRequired,
  isCollapsed: PropTypes.bool,
  mq: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
Collapsable.defaultProps = {
  isCollapsed: true,
  className: '',
};

export default Collapsable;
