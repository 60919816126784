import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

/** Layouts */
import Registration from './layouts/sections/Registration';

/** Components */
import Modal from './Modal';
import Button from './Button';

/**
 * <RegistrationModal />
 */

const RegistrationModal = ({ cta, buttonProps }) => {
  const modalRef = useRef();

  /** Overlay props */
  const buttonRef = useRef();
  const overlayState = useOverlayTriggerState({});
  const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'dialog' }, overlayState, buttonRef);

  return (
    <>
      <Button ref={buttonRef} {...triggerProps} className="modal-button" {...buttonProps}>
        {cta}
      </Button>
      {overlayState.isOpen && (
        <Modal ref={modalRef} {...overlayProps} state={overlayState} className="registration-modal">
          <Registration onClose={() => modalRef.current.close()} />
        </Modal>
      )}
    </>
  );
};

RegistrationModal.propTypes = {
  /** The text of the button */
  cta: PropTypes.string.isRequired,
  /** The properties of the button */
  buttonProps: PropTypes.shape({
    /** The layout of the button */
    layout: PropTypes.string,
    /** The color of the button */
    color: PropTypes.string,
  }).isRequired,
};

export default RegistrationModal;
