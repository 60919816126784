import { useEffect, lazy, Suspense } from 'react';

/** Hooks */
import { useCustomResize } from './hooks/useCustomResize';
import { useScrollbarWidth } from './hooks/useScrollbarWidth';

/** Components */
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import Hero from './components/layouts/sections/Hero';
import Catalog from './components/layouts/sections/Catalog';
import Schools from './components/layouts/sections/Schools';
import Experience from './components/layouts/sections/Experience';
import Services from './components/layouts/sections/Services';
import Cta from './components/layouts/sections/Cta';
import About from './components/layouts/sections/About';

/** Styles */
import './fonts/inseecu.css';
import './fonts/inter.css';
import './fonts/icomoon.scss';
import './App.scss';

const App = () => {
  useCustomResize();
  useScrollbarWidth();

  /** Remove the loader */
  useEffect(() => {
    const body = document.querySelector('body');
    const loader = document.getElementById('loader');
    body.classList.remove('is-loading');
    loader.classList.remove('is-loading');
    /** Add a delay to match the css transition in _loader.scss */
    setTimeout(() => {
      loader.classList.add('is-loaded');
    }, 400);
  }, []);

  /** Prevent iPhone devices from auto-zoom the viewport when focusing a form input */
  useEffect(() => {
    if (navigator.userAgent.indexOf('iPhone') > -1) {
      document
        .querySelector('[name=viewport]')
        .setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
    }
  }, []);

  /** Add development tools */
  const DevTools = process.env.NODE_ENV === 'development' ? lazy(() => import('./Dev')) : <></>;

  return (
    <>
      <a href="#main" className="visually-hidden">
        Aller au contenu
      </a>
      <Header />
      <main id="main">
        <Hero />
        <Schools />
        <About />
        <Catalog />
        <Experience />
        <Services />
        <Cta />
      </main>
      <Footer />
      <Suspense fallback={<></>}>{process.env.NODE_ENV === 'development' && <DevTools />}</Suspense>
    </>
  );
};

export default App;
