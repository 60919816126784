/**
 * useUniqueId Hook
 *
 * Raturn a string or an array of unique id('s)
 *
 * @param {number} length  How many id's to create
 * @returns {string|string[]}  An unique id or an array of id's
 */
const useUniqueId = (length = 1) => {
  const createId = () => `uid-${Math.round(Math.random() * 10000000000)}`;
  return length === 1 ? createId() : Array.apply(null, { length }).map(createId);
};

export { useUniqueId };
export default useUniqueId;
