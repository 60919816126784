import PropTypes from 'prop-types';
import { useRef, forwardRef, createContext, useContext } from 'react';
import { useRadioGroup, useRadio, useFocusRing } from 'react-aria';
import { useRadioGroupState } from 'react-stately';

const RadioGroupContext = createContext(null);

/**
 * <RadioGroup />
 */

const RadioGroup = forwardRef(({ className, ...props }, groupRef) => {
  const { radios, logos, disabled, label, description, errorMessage, displayLabel } = props;
  const groupState = useRadioGroupState(props);
  const { groupProps, labelProps, descriptionProps, errorMessageProps } = useRadioGroup(props, groupState);

  return (
    <div ref={groupRef} {...groupProps} className={`radio-group ${className}`}>
      {label && <span {...labelProps}>{label}</span>}
      <RadioGroupContext.Provider value={groupState}>
        {radios.map((value, index) => {
          const label = typeof value === 'object' ? value.label : value;
          typeof value === 'object' && (value = value.value);
          return (
            <Radio key={index} value={value} isDisabled={disabled.includes(value)}>
              {logos && logos[index] && <img src={logos[index]} alt={value} />}{' '}
              {(!Array.isArray(logos) || logos[index] === undefined || displayLabel) && label}
            </Radio>
          );
        })}
      </RadioGroupContext.Provider>
      {description && (
        <div {...descriptionProps} className="field-description">
          {description}
        </div>
      )}
      {errorMessage && (
        <div {...errorMessageProps} className="field-error">
          {errorMessage}
        </div>
      )}
    </div>
  );
});

RadioGroup.displayName = 'RadioGroup';
RadioGroup.propTypes = {
  /** The class names to add to the element */
  className: PropTypes.string,
  /** The label of the radio group */
  label: PropTypes.string,
  /** The description of the group */
  description: PropTypes.string,
  /** The error message of the group */
  errorMessage: PropTypes.string,
  /** The values of the radios */
  radios: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
      }),
    ])
  ).isRequired,
  /** Logos to use instead of radios labels */
  logos: PropTypes.arrayOf(PropTypes.string),
  /** If logos are used, whether to display the label text next to the image */
  displayLabel: PropTypes.bool,
  /** The currently selected elements */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The radios that are disabled */
  disabled: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  /** A callback for the onChange event */
  onChange: PropTypes.func.isRequired,
};
RadioGroup.defaultProps = {
  className: '',
  displayLabel: false,
  disabled: [],
};

/**
 * <Radio />
 */

const Radio = (props) => {
  const { value, children } = props;

  const inputRef = useRef();
  const inputState = useContext(RadioGroupContext);
  const { inputProps } = useRadio(props, inputState, inputRef);
  const { isFocusVisible, focusProps } = useFocusRing();

  const isSelected = inputState.selectedValue === value;
  const isDisabled = inputState.isDisabled || props.isDisabled;

  return (
    <label className={`radio-label ${isDisabled ? 'is-disabled' : ''} ${isSelected ? 'is-selected' : ''}`}>
      <span className="visually-hidden">
        <input ref={inputRef} {...inputProps} {...focusProps} />
      </span>
      <span
        className={`radio ${isSelected ? 'is-selected' : ''} ${isFocusVisible ? 'is-focused' : ''}`}
        aria-hidden="true"
      />
      {typeof children === 'string' ? <span dangerouslySetInnerHTML={{ __html: children }} /> : <span>{children}</span>}
    </label>
  );
};

Radio.propTypes = {
  /** The value of the radio */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** Whether the radio is disabled */
  isDisabled: PropTypes.bool,
  /** The label of the radio */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default RadioGroup;
