import PropTypes from 'prop-types';
import { useState } from 'react';

/** Hooks */
import { useMenu } from '../../../hooks/useMenu';
import { useUniqueId } from '../../../hooks/useUniqueId';

/** Components */
import RadioGroup from './RadioGroup';
import Menu from '../../Menu';
import Button from '../../Button';

/** Data */
import countries from '../../../data/countries';

/**
 * <CountrySelector />
 */

const CountrySelector = ({ fieldName, ...props }) => {
  const legendId = useUniqueId();

  const defaultCountry = countries.filter((country) => country.code === 'fr')[0];
  const [value, setValue] = useState(defaultCountry.dial);
  const [flag, setFlag] = useState(defaultCountry.flag);

  /** Menu refs, state and props */
  const { menuTriggerRef, menuTriggerProps, menuRef, menuState, menuProps } = useMenu(props);

  return (
    <div className="select country-selector">
      <Button
        ref={menuTriggerRef}
        {...menuTriggerProps}
        className="country-selector-button"
        ariaLabel="Choisissez votre pays"
      >
        <img src={flag} alt={value} />{' '}
      </Button>
      <input type="hidden" name={fieldName} value={value} />

      {menuState.isOpen && (
        <Menu ref={menuRef} {...menuProps} position="left">
          <fieldset className="field-overlay">
            <legend id={legendId} className="visually-hidden">
              Choisissez votre pays
            </legend>
            <RadioGroup
              className="country-selector-radio-group"
              radios={countries.map((country) => ({
                label: <><span>{country.name}</span> {country.dial}</>, // prettier-ignore
                value: country.dial,
              }))}
              logos={countries.map((country) => `${process.env.PUBLIC_URL}/flags/${country.code}.svg`)}
              displayLabel={true}
              aria-labelledby={legendId}
              value={value}
              onChange={(value) => {
                setFlag(countries.filter((country) => country.dial === value)[0].flag);
                setValue(value);
                menuRef.current.close();
              }}
            />
          </fieldset>
        </Menu>
      )}
    </div>
  );
};

CountrySelector.propTypes = {
  /** The name of the field containing the value */
  fieldName: PropTypes.string.isRequired,
};

export default CountrySelector;
