/** Layouts */
import { Section, SectionTitle, SectionContent } from '../Section';
import Container from '../Container';

/** Components */
import Icon from '../../Icon';

/**
 * <Services />
 */

const Services = (props) => {
  return (
    <Section className="services bg-grey-light">
      <Container layout="narrow">
        <SectionTitle>Découvrez notre gamme de services premium</SectionTitle>
        <SectionContent>
          <article className="article services-article">
            <span className="icon-wrapper">
              <Icon icon="om" ariaHidden />
            </span>
            <h3>Votre compagnon d’apprentissage personnalisé</h3>
            <p>
              Un compagnon virtuel intégré à votre plateforme, pour répondre à vos questions et vous apporter des
              conseils pédagogiques adaptés à votre profil
            </p>
          </article>
          <article className="article services-article">
            <span className="icon-wrapper">
              <Icon icon="platform" ariaHidden />
            </span>
            <h3>Une plateforme de services intégrés sur chaque campus, pour vous accompagner dans tous vos projets</h3>
            <p>
              Des services inter-écoles et multi-disciplinaires, tels que la Fabrik ou le Service des Relations
              Entreprise
            </p>
          </article>
          <article className="article services-article">
            <span className="icon-wrapper">
              <Icon icon="library" ariaHidden />
            </span>
            <h3>
              Une bibliothèque
              <br />
              100% digitale
            </h3>
            <p>
              Des milliers de ebooks, d’articles, de rapports et documents audiovisuels, accessibles de n’importe où, à
              tout moment
            </p>
          </article>
        </SectionContent>
      </Container>
    </Section>
  );
};

export default Services;
