import { useCallback } from 'react';

/**
 * useKalturaPlayer Hook
 *
 * Load the Kaltura Player script after detecting if it is not already present.
 *
 * @returns {Function}  The hook callback function
 */
const useKalturaPlayer = () => {
  /**
   * loadKalturaPlayer callback function
   *
   * @param {object} param  The Kaltura video parameters
   * @param {string} param.partnerId  The Kaltura `partnerId`
   * @param {string} param.uiConfId  The Kaltura `uiConfId`
   * @returns {Promise}  A promise to the loaded script
   */
  const loadKalturaPlayer = useCallback(
    ({ partnerId, uiConfId }) =>
      new Promise((resolve, reject) => {
        if (partnerId === undefined || uiConfId === undefined) {
          return reject('useKalturaPlayer: Missing parameters');
        }
        const scriptId = `Kaltura-script-${partnerId}-${uiConfId}`;
        if (document.getElementById(scriptId) === null) {
          const script = document.createElement('script');
          script.id = scriptId;
          script.src = `https://api.irp2.ovp.kaltura.com/p/${partnerId}/embedPlaykitJs/uiconf_id/${uiConfId}`;
          script.addEventListener('load', () => resolve());
          document.querySelector('body').appendChild(script);
        } else {
          return resolve();
        }
      }),
    []
  );

  /** Return the hook callback function */
  return loadKalturaPlayer;
};

export { useKalturaPlayer };
export default useKalturaPlayer;
