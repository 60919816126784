import { useCallback } from 'react';

/**
 * useOnLoad Hook
 *
 * Fires a "load" event to the `window` object.
 * The event name can be prefixed.
 *
 * @param {string} prefix  The prefix to add to the "load" event
 * @returns {Function}  The hook callback function
 * @fires window#load || window#{prefix|prefixOverride}load
 */
const useOnLoad = (prefix) => {
  /**
   * onLoad callback function
   *
   * @param {string} prefix  The prefix to add to the load event
   */
  const onLoad = useCallback(
    (prefixOverride) => {
      /**
       * Fires a `load` event.
       *
       * @fires window#load || window#{prefix|prefixOverride}load
       */
      window.dispatchEvent(new CustomEvent(`${prefix || prefixOverride || ''}load`));
    },
    [prefix]
  );

  /** Return the hook callback function */
  return onLoad;
};

export { useOnLoad };
export default useOnLoad;
