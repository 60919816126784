/**
 * Return the absolute path of a file
 *
 * @param {string} fileUrl  The url of the file
 * @returns {string}  The absolute url
 */
export const getAbsolutePath = (fileUrl) => {
  return process.env.NODE_ENV === 'development'
    ? fileUrl
    : process.env.PUBLIC_URL.startsWith('.')
    ? fileUrl.replace('./', '/')
    : fileUrl.replace('./', `${process.env.PUBLIC_URL}/`);
};
