/**
 * This file is generated by ./countries.php, you should not edit it directly
 */

// prettier-ignore
let countries = [
  { name: 'United States', code: 'us', dial: '+1' },
  { name: 'United Kingdom', code: 'gb', dial: '+44' },
  { name: 'Afghanistan (\u202b\u0627\u0641\u063a\u0627\u0646\u0633\u062a\u0627\u0646\u202c\u200e)', code: 'af', dial: '+93' },
  { name: 'Albania (Shqip\u00ebri)', code: 'al', dial: '+355' },
  { name: 'Algeria (\u202b\u0627\u0644\u062c\u0632\u0627\u0626\u0631\u202c\u200e)', code: 'dz', dial: '+213' },
  { name: 'American Samoa', code: 'as', dial: '+1684' },
  { name: 'Andorra', code: 'ad', dial: '+376' },
  { name: 'Angola', code: 'ao', dial: '+244' },
  { name: 'Anguilla', code: 'ai', dial: '+1264' },
  { name: 'Antigua and Barbuda', code: 'ag', dial: '+1268' },
  { name: 'Argentina', code: 'ar', dial: '+54' },
  { name: 'Armenia (\u0540\u0561\u0575\u0561\u057d\u057f\u0561\u0576)', code: 'am', dial: '+374' },
  { name: 'Aruba', code: 'aw', dial: '+297' },
  { name: 'Australia', code: 'au', dial: '+61' },
  { name: 'Austria (\u00d6sterreich)', code: 'at', dial: '+43' },
  { name: 'Azerbaijan (Az\u0259rbaycan)', code: 'az', dial: '+994' },
  { name: 'Bahamas', code: 'bs', dial: '+1242' },
  { name: 'Bahrain (\u202b\u0627\u0644\u0628\u062d\u0631\u064a\u0646\u202c\u200e)', code: 'bh', dial: '+973' },
  { name: 'Bangladesh (\u09ac\u09be\u0982\u09b2\u09be\u09a6\u09c7\u09b6)', code: 'bd', dial: '+880' },
  { name: 'Barbados', code: 'bb', dial: '+1246' },
  { name: 'Belarus (\u0411\u0435\u043b\u0430\u0440\u0443\u0441\u044c)', code: 'by', dial: '+375' },
  { name: 'Belgium (Belgi\u00eb)', code: 'be', dial: '+32' },
  { name: 'Belize', code: 'bz', dial: '+501' },
  { name: 'Benin (B\u00e9nin)', code: 'bj', dial: '+229' },
  { name: 'Bermuda', code: 'bm', dial: '+1441' },
  { name: 'Bhutan (\u0f60\u0f56\u0fb2\u0f74\u0f42)', code: 'bt', dial: '+975' },
  { name: 'Bolivia', code: 'bo', dial: '+591' },
  { name: 'Bosnia and Herzegovina (\u0411\u043e\u0441\u043d\u0430 \u0438 \u0425\u0435\u0440\u0446\u0435\u0433\u043e\u0432\u0438\u043d\u0430)', code: 'ba', dial: '+387' },
  { name: 'Botswana', code: 'bw', dial: '+267' },
  { name: 'Brazil (Brasil)', code: 'br', dial: '+55' },
  { name: 'British Indian Ocean Territory', code: 'io', dial: '+246' },
  { name: 'British Virgin Islands', code: 'vg', dial: '+1284' },
  { name: 'Brunei', code: 'bn', dial: '+673' },
  { name: 'Bulgaria (\u0411\u044a\u043b\u0433\u0430\u0440\u0438\u044f)', code: 'bg', dial: '+359' },
  { name: 'Burkina Faso', code: 'bf', dial: '+226' },
  { name: 'Burundi (Uburundi)', code: 'bi', dial: '+257' },
  { name: 'Cambodia (\u1780\u1798\u17d2\u1796\u17bb\u1787\u17b6)', code: 'kh', dial: '+855' },
  { name: 'Cameroon (Cameroun)', code: 'cm', dial: '+237' },
  { name: 'Canada', code: 'ca', dial: '+1' },
  { name: 'Cape Verde (Kabu Verdi)', code: 'cv', dial: '+238' },
  { name: 'Caribbean Netherlands', code: 'bq', dial: '+599' },
  { name: 'Cayman Islands', code: 'ky', dial: '+1345' },
  { name: 'Central African Republic (R\u00e9publique centrafricaine)', code: 'cf', dial: '+236' },
  { name: 'Chad (Tchad)', code: 'td', dial: '+235' },
  { name: 'Chile', code: 'cl', dial: '+56' },
  { name: 'China (\u4e2d\u56fd)', code: 'cn', dial: '+86' },
  { name: 'Christmas Island', code: 'cx', dial: '+61' },
  { name: 'Cocos (Keeling) Islands', code: 'cc', dial: '+61' },
  { name: 'Colombia', code: 'co', dial: '+57' },
  { name: 'Comoros (\u202b\u062c\u0632\u0631 \u0627\u0644\u0642\u0645\u0631\u202c\u200e)', code: 'km', dial: '+269' },
  { name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)', code: 'cd', dial: '+243' },
  { name: 'Congo (Republic) (Congo-Brazzaville)', code: 'cg', dial: '+242' },
  { name: 'Cook Islands', code: 'ck', dial: '+682' },
  { name: 'Costa Rica', code: 'cr', dial: '+506' },
  { name: 'C\u00f4te d\u2019Ivoire', code: 'ci', dial: '+225' },
  { name: 'Croatia (Hrvatska)', code: 'hr', dial: '+385' },
  { name: 'Cuba', code: 'cu', dial: '+53' },
  { name: 'Cura\u00e7ao', code: 'cw', dial: '+599' },
  { name: 'Cyprus (\u039a\u03cd\u03c0\u03c1\u03bf\u03c2)', code: 'cy', dial: '+357' },
  { name: 'Czech Republic (\u010cesk\u00e1 republika)', code: 'cz', dial: '+420' },
  { name: 'Denmark (Danmark)', code: 'dk', dial: '+45' },
  { name: 'Djibouti', code: 'dj', dial: '+253' },
  { name: 'Dominica', code: 'dm', dial: '+1767' },
  { name: 'Dominican Republic (Rep\u00fablica Dominicana)', code: 'do', dial: '+1' },
  { name: 'Ecuador', code: 'ec', dial: '+593' },
  { name: 'Egypt (\u202b\u0645\u0635\u0631\u202c\u200e)', code: 'eg', dial: '+20' },
  { name: 'El Salvador', code: 'sv', dial: '+503' },
  { name: 'Equatorial Guinea (Guinea Ecuatorial)', code: 'gq', dial: '+240' },
  { name: 'Eritrea', code: 'er', dial: '+291' },
  { name: 'Estonia (Eesti)', code: 'ee', dial: '+372' },
  { name: 'Ethiopia', code: 'et', dial: '+251' },
  { name: 'Falkland Islands (Islas Malvinas)', code: 'fk', dial: '+500' },
  { name: 'Faroe Islands (F\u00f8royar)', code: 'fo', dial: '+298' },
  { name: 'Fiji', code: 'fj', dial: '+679' },
  { name: 'Finland (Suomi)', code: 'fi', dial: '+358' },
  { name: 'France', code: 'fr', dial: '+33' },
  { name: 'French Guiana (Guyane fran\u00e7aise)', code: 'gf', dial: '+594' },
  { name: 'French Polynesia (Polyn\u00e9sie fran\u00e7aise)', code: 'pf', dial: '+689' },
  { name: 'Gabon', code: 'ga', dial: '+241' },
  { name: 'Gambia', code: 'gm', dial: '+220' },
  { name: 'Georgia (\u10e1\u10d0\u10e5\u10d0\u10e0\u10d7\u10d5\u10d4\u10da\u10dd)', code: 'ge', dial: '+995' },
  { name: 'Germany (Deutschland)', code: 'de', dial: '+49' },
  { name: 'Ghana (Gaana)', code: 'gh', dial: '+233' },
  { name: 'Gibraltar', code: 'gi', dial: '+350' },
  { name: 'Greece (\u0395\u03bb\u03bb\u03ac\u03b4\u03b1)', code: 'gr', dial: '+30' },
  { name: 'Greenland (Kalaallit Nunaat)', code: 'gl', dial: '+299' },
  { name: 'Grenada', code: 'gd', dial: '+1473' },
  { name: 'Guadeloupe', code: 'gp', dial: '+590' },
  { name: 'Guam', code: 'gu', dial: '+1671' },
  { name: 'Guatemala', code: 'gt', dial: '+502' },
  { name: 'Guernsey', code: 'gg', dial: '+44' },
  { name: 'Guinea (Guin\u00e9e)', code: 'gn', dial: '+224' },
  { name: 'Guinea-Bissau (Guin\u00e9 Bissau)', code: 'gw', dial: '+245' },
  { name: 'Guyana', code: 'gy', dial: '+592' },
  { name: 'Haiti', code: 'ht', dial: '+509' },
  { name: 'Honduras', code: 'hn', dial: '+504' },
  { name: 'Hong Kong (\u9999\u6e2f)', code: 'hk', dial: '+852' },
  { name: 'Hungary (Magyarorsz\u00e1g)', code: 'hu', dial: '+36' },
  { name: 'Iceland (\u00cdsland)', code: 'is', dial: '+354' },
  { name: 'India (\u092d\u093e\u0930\u0924)', code: 'in', dial: '+91' },
  { name: 'Indonesia', code: 'id', dial: '+62' },
  { name: 'Iran (\u202b\u0627\u06cc\u0631\u0627\u0646\u202c\u200e)', code: 'ir', dial: '+98' },
  { name: 'Iraq (\u202b\u0627\u0644\u0639\u0631\u0627\u0642\u202c\u200e)', code: 'iq', dial: '+964' },
  { name: 'Ireland', code: 'ie', dial: '+353' },
  { name: 'Isle of Man', code: 'im', dial: '+44' },
  { name: 'Israel (\u202b\u05d9\u05e9\u05e8\u05d0\u05dc\u202c\u200e)', code: 'il', dial: '+972' },
  { name: 'Italy (Italia)', code: 'it', dial: '+39' },
  { name: 'Jamaica', code: 'jm', dial: '+1' },
  { name: 'Japan (\u65e5\u672c)', code: 'jp', dial: '+81' },
  { name: 'Jersey', code: 'je', dial: '+44' },
  { name: 'Jordan (\u202b\u0627\u0644\u0623\u0631\u062f\u0646\u202c\u200e)', code: 'jo', dial: '+962' },
  { name: 'Kazakhstan (\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d)', code: 'kz', dial: '+7' },
  { name: 'Kenya', code: 'ke', dial: '+254' },
  { name: 'Kiribati', code: 'ki', dial: '+686' },
  { name: 'Kosovo', code: 'xk', dial: '+383' },
  { name: 'Kuwait (\u202b\u0627\u0644\u0643\u0648\u064a\u062a\u202c\u200e)', code: 'kw', dial: '+965' },
  { name: 'Kyrgyzstan (\u041a\u044b\u0440\u0433\u044b\u0437\u0441\u0442\u0430\u043d)', code: 'kg', dial: '+996' },
  { name: 'Laos (\u0ea5\u0eb2\u0ea7)', code: 'la', dial: '+856' },
  { name: 'Latvia (Latvija)', code: 'lv', dial: '+371' },
  { name: 'Lebanon (\u202b\u0644\u0628\u0646\u0627\u0646\u202c\u200e)', code: 'lb', dial: '+961' },
  { name: 'Lesotho', code: 'ls', dial: '+266' },
  { name: 'Liberia', code: 'lr', dial: '+231' },
  { name: 'Libya (\u202b\u0644\u064a\u0628\u064a\u0627\u202c\u200e)', code: 'ly', dial: '+218' },
  { name: 'Liechtenstein', code: 'li', dial: '+423' },
  { name: 'Lithuania (Lietuva)', code: 'lt', dial: '+370' },
  { name: 'Luxembourg', code: 'lu', dial: '+352' },
  { name: 'Macau (\u6fb3\u9580)', code: 'mo', dial: '+853' },
  { name: 'Macedonia (FYROM) (\u041c\u0430\u043a\u0435\u0434\u043e\u043d\u0438\u0458\u0430)', code: 'mk', dial: '+389' },
  { name: 'Madagascar (Madagasikara)', code: 'mg', dial: '+261' },
  { name: 'Malawi', code: 'mw', dial: '+265' },
  { name: 'Malaysia', code: 'my', dial: '+60' },
  { name: 'Maldives', code: 'mv', dial: '+960' },
  { name: 'Mali', code: 'ml', dial: '+223' },
  { name: 'Malta', code: 'mt', dial: '+356' },
  { name: 'Marshall Islands', code: 'mh', dial: '+692' },
  { name: 'Martinique', code: 'mq', dial: '+596' },
  { name: 'Mauritania (\u202b\u0645\u0648\u0631\u064a\u062a\u0627\u0646\u064a\u0627\u202c\u200e)', code: 'mr', dial: '+222' },
  { name: 'Mauritius (Moris)', code: 'mu', dial: '+230' },
  { name: 'Mayotte', code: 'yt', dial: '+262' },
  { name: 'Mexico (M\u00e9xico)', code: 'mx', dial: '+52' },
  { name: 'Micronesia', code: 'fm', dial: '+691' },
  { name: 'Moldova (Republica Moldova)', code: 'md', dial: '+373' },
  { name: 'Monaco', code: 'mc', dial: '+377' },
  { name: 'Mongolia (\u041c\u043e\u043d\u0433\u043e\u043b)', code: 'mn', dial: '+976' },
  { name: 'Montenegro (Crna Gora)', code: 'me', dial: '+382' },
  { name: 'Montserrat', code: 'ms', dial: '+1664' },
  { name: 'Morocco (\u202b\u0627\u0644\u0645\u063a\u0631\u0628\u202c\u200e)', code: 'ma', dial: '+212' },
  { name: 'Mozambique (Mo\u00e7ambique)', code: 'mz', dial: '+258' },
  { name: 'Myanmar (Burma) (\u1019\u103c\u1014\u103a\u1019\u102c)', code: 'mm', dial: '+95' },
  { name: 'Namibia (Namibi\u00eb)', code: 'na', dial: '+264' },
  { name: 'Nauru', code: 'nr', dial: '+674' },
  { name: 'Nepal (\u0928\u0947\u092a\u093e\u0932)', code: 'np', dial: '+977' },
  { name: 'Netherlands (Nederland)', code: 'nl', dial: '+31' },
  { name: 'New Caledonia (Nouvelle-Cal\u00e9donie)', code: 'nc', dial: '+687' },
  { name: 'New Zealand', code: 'nz', dial: '+64' },
  { name: 'Nicaragua', code: 'ni', dial: '+505' },
  { name: 'Niger (Nijar)', code: 'ne', dial: '+227' },
  { name: 'Nigeria', code: 'ng', dial: '+234' },
  { name: 'Niue', code: 'nu', dial: '+683' },
  { name: 'Norfolk Island', code: 'nf', dial: '+672' },
  { name: 'North Korea (\uc870\uc120 \ubbfc\uc8fc\uc8fc\uc758 \uc778\ubbfc \uacf5\ud654\uad6d)', code: 'kp', dial: '+850' },
  { name: 'Northern Mariana Islands', code: 'mp', dial: '+1670' },
  { name: 'Norway (Norge)', code: 'no', dial: '+47' },
  { name: 'Oman (\u202b\u0639\u064f\u0645\u0627\u0646\u202c\u200e)', code: 'om', dial: '+968' },
  { name: 'Pakistan (\u202b\u067e\u0627\u06a9\u0633\u062a\u0627\u0646\u202c\u200e)', code: 'pk', dial: '+92' },
  { name: 'Palau', code: 'pw', dial: '+680' },
  { name: 'Palestine (\u202b\u0641\u0644\u0633\u0637\u064a\u0646\u202c\u200e)', code: 'ps', dial: '+970' },
  { name: 'Panama (Panam\u00e1)', code: 'pa', dial: '+507' },
  { name: 'Papua New Guinea', code: 'pg', dial: '+675' },
  { name: 'Paraguay', code: 'py', dial: '+595' },
  { name: 'Peru (Per\u00fa)', code: 'pe', dial: '+51' },
  { name: 'Philippines', code: 'ph', dial: '+63' },
  { name: 'Poland (Polska)', code: 'pl', dial: '+48' },
  { name: 'Portugal', code: 'pt', dial: '+351' },
  { name: 'Puerto Rico', code: 'pr', dial: '+1' },
  { name: 'Qatar (\u202b\u0642\u0637\u0631\u202c\u200e)', code: 'qa', dial: '+974' },
  { name: 'R\u00e9union (La R\u00e9union)', code: 're', dial: '+262' },
  { name: 'Romania (Rom\u00e2nia)', code: 'ro', dial: '+40' },
  { name: 'Russia (\u0420\u043e\u0441\u0441\u0438\u044f)', code: 'ru', dial: '+7' },
  { name: 'Rwanda', code: 'rw', dial: '+250' },
  { name: 'Saint Barth\u00e9lemy', code: 'bl', dial: '+590' },
  { name: 'Saint Helena', code: 'sh', dial: '+290' },
  { name: 'Saint Kitts and Nevis', code: 'kn', dial: '+1869' },
  { name: 'Saint Lucia', code: 'lc', dial: '+1758' },
  { name: 'Saint Martin (Saint-Martin (partie fran\u00e7aise))', code: 'mf', dial: '+590' },
  { name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)', code: 'pm', dial: '+508' },
  { name: 'Saint Vincent and the Grenadines', code: 'vc', dial: '+1784' },
  { name: 'Samoa', code: 'ws', dial: '+685' },
  { name: 'San Marino', code: 'sm', dial: '+378' },
  { name: 'S\u00e3o Tom\u00e9 and Pr\u00edncipe (S\u00e3o Tom\u00e9 e Pr\u00edncipe)', code: 'st', dial: '+239' },
  { name: 'Saudi Arabia (\u202b\u0627\u0644\u0645\u0645\u0644\u0643\u0629 \u0627\u0644\u0639\u0631\u0628\u064a\u0629 \u0627\u0644\u0633\u0639\u0648\u062f\u064a\u0629\u202c\u200e)', code: 'sa', dial: '+966' },
  { name: 'Senegal (S\u00e9n\u00e9gal)', code: 'sn', dial: '+221' },
  { name: 'Serbia (\u0421\u0440\u0431\u0438\u0458\u0430)', code: 'rs', dial: '+381' },
  { name: 'Seychelles', code: 'sc', dial: '+248' },
  { name: 'Sierra Leone', code: 'sl', dial: '+232' },
  { name: 'Singapore', code: 'sg', dial: '+65' },
  { name: 'Sint Maarten', code: 'sx', dial: '+1721' },
  { name: 'Slovakia (Slovensko)', code: 'sk', dial: '+421' },
  { name: 'Slovenia (Slovenija)', code: 'si', dial: '+386' },
  { name: 'Solomon Islands', code: 'sb', dial: '+677' },
  { name: 'Somalia (Soomaaliya)', code: 'so', dial: '+252' },
  { name: 'South Africa', code: 'za', dial: '+27' },
  { name: 'South Korea (\ub300\ud55c\ubbfc\uad6d)', code: 'kr', dial: '+82' },
  { name: 'South Sudan (\u202b\u062c\u0646\u0648\u0628 \u0627\u0644\u0633\u0648\u062f\u0627\u0646\u202c\u200e)', code: 'ss', dial: '+211' },
  { name: 'Spain (Espa\u00f1a)', code: 'es', dial: '+34' },
  { name: 'Sri Lanka (\u0dc1\u0dca\u200d\u0dbb\u0dd3 \u0dbd\u0d82\u0d9a\u0dcf\u0dc0)', code: 'lk', dial: '+94' },
  { name: 'Sudan (\u202b\u0627\u0644\u0633\u0648\u062f\u0627\u0646\u202c\u200e)', code: 'sd', dial: '+249' },
  { name: 'Suriname', code: 'sr', dial: '+597' },
  { name: 'Svalbard and Jan Mayen', code: 'sj', dial: '+47' },
  { name: 'Swaziland', code: 'sz', dial: '+268' },
  { name: 'Sweden (Sverige)', code: 'se', dial: '+46' },
  { name: 'Switzerland (Schweiz)', code: 'ch', dial: '+41' },
  { name: 'Syria (\u202b\u0633\u0648\u0631\u064a\u0627\u202c\u200e)', code: 'sy', dial: '+963' },
  { name: 'Taiwan (\u53f0\u7063)', code: 'tw', dial: '+886' },
  { name: 'Tajikistan', code: 'tj', dial: '+992' },
  { name: 'Tanzania', code: 'tz', dial: '+255' },
  { name: 'Thailand (\u0e44\u0e17\u0e22)', code: 'th', dial: '+66' },
  { name: 'Timor-Leste', code: 'tl', dial: '+670' },
  { name: 'Togo', code: 'tg', dial: '+228' },
  { name: 'Tokelau', code: 'tk', dial: '+690' },
  { name: 'Tonga', code: 'to', dial: '+676' },
  { name: 'Trinidad and Tobago', code: 'tt', dial: '+1868' },
  { name: 'Tunisia (\u202b\u062a\u0648\u0646\u0633\u202c\u200e)', code: 'tn', dial: '+216' },
  { name: 'Turkey (T\u00fcrkiye)', code: 'tr', dial: '+90' },
  { name: 'Turkmenistan', code: 'tm', dial: '+993' },
  { name: 'Turks and Caicos Islands', code: 'tc', dial: '+1649' },
  { name: 'Tuvalu', code: 'tv', dial: '+688' },
  { name: 'U.S. Virgin Islands', code: 'vi', dial: '+1340' },
  { name: 'Uganda', code: 'ug', dial: '+256' },
  { name: 'Ukraine (\u0423\u043a\u0440\u0430\u0457\u043d\u0430)', code: 'ua', dial: '+380' },
  { name: 'United Arab Emirates (\u202b\u0627\u0644\u0625\u0645\u0627\u0631\u0627\u062a \u0627\u0644\u0639\u0631\u0628\u064a\u0629 \u0627\u0644\u0645\u062a\u062d\u062f\u0629\u202c\u200e)', code: 'ae', dial: '+971' },
  { name: 'United Kingdom', code: 'gb', dial: '+44' },
  { name: 'United States', code: 'us', dial: '+1' },
  { name: 'Uruguay', code: 'uy', dial: '+598' },
  { name: 'Uzbekistan (O\u02bbzbekiston)', code: 'uz', dial: '+998' },
  { name: 'Vanuatu', code: 'vu', dial: '+678' },
  { name: 'Vatican City (Citt\u00e0 del Vaticano)', code: 'va', dial: '+39' },
  { name: 'Venezuela', code: 've', dial: '+58' },
  { name: 'Vietnam (Vi\u1ec7t Nam)', code: 'vn', dial: '+84' },
  { name: 'Wallis and Futuna (Wallis-et-Futuna)', code: 'wf', dial: '+681' },
  { name: 'Western Sahara (\u202b\u0627\u0644\u0635\u062d\u0631\u0627\u0621 \u0627\u0644\u063a\u0631\u0628\u064a\u0629\u202c\u200e)', code: 'eh', dial: '+212' },
  { name: 'Yemen (\u202b\u0627\u0644\u064a\u0645\u0646\u202c\u200e)', code: 'ye', dial: '+967' },
  { name: 'Zambia', code: 'zm', dial: '+260' },
  { name: 'Zimbabwe', code: 'zw', dial: '+263' },
  { name: '\u00c5land Islands', code: 'ax', dial: '+358' },
];

countries = countries
  .map((country) => ({ ...country, flag: `${process.env.PUBLIC_URL}/flags/${country.code}.svg` }))
  .sort((a, b) => a.name.localeCompare(b.name));

export default countries;
