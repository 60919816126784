/** Layouts */
import { Section, SectionTitle, SectionContent } from '../Section';
import Container from '../Container';

/** Components */
import logo from '../../../images/logo-omnes-education.png';
import CountUp from '../../CountUp';

const numbers = [
  { number: 15, text: 'écoles', duration: 2 },
  { number: 200000, text: 'alumni', duration: 6 },
  { number: 3000, text: 'experts', duration: 3.5 },
  { number: 40000, text: 'étudiants', duration: 4.5 },
  { number: 19, text: 'Campus en France et à l’étranger', duration: 2.5 },
];

/**
 * <About />
 */

const About = (props) => {
  return (
    <Section className="about bg-primary">
      <Container>
        <SectionTitle className="h3">
          <img src={logo} alt="Omnes Education" />
        </SectionTitle>
        <SectionContent>
          <p>
            OMNES Education est une institution privée d’enseignement supérieur et de recherche interdisciplinaire,
            implantée à Beaune, Bordeaux, Chambéry, Lyon, Rennes, Marseille et Paris. Avec ses campus à Abidjan,
            Barcelone, Genève, Lausanne, Londres, Monaco, Munich, et San Francisco, OMNES Education occupe une place
            unique dans le paysage éducatif français.
          </p>
        </SectionContent>
        <SectionContent className="numbers">
          <ul>
            {numbers.map(({ number, text, duration }, index) => (
              <li key={index}>
                <CountUp number={number} text={text} duration={duration} />
              </li>
            ))}
          </ul>
        </SectionContent>
      </Container>
    </Section>
  );
};

export default About;
