import { useCallback } from 'react';

/** Utils */
import { hasProperty } from '../util/Object';

/**
 * useFormValidation Hook
 *
 * Validate a form.
 *
 * @param {string[]} fields  The form fields
 * @param {string} action  The form action
 * @returns {Function}  The hook callback function
 */
const useFormValidation = (fields, action) => {
  /**
   * validateForm callback function
   *
   * @param {HTMLElement} form  The form to validate
   * @returns {Array} result
   * @returns {boolean} result.0  Whether the form is valid
   * @returns {object} result.1  An object containing the form errors
   */
  const validateForm = useCallback(
    (form) => {
      form = form && form.current ? form.current : form;
      const formData = new FormData(form);
      const errors = {};

      /** Required fields */
      fields
        .filter((field) => field.isRequired)
        .forEach(({ name }) => {
          if (!formData.has(name) || formData.get(name).length === 0) {
            errors[name] = 'Ce champ est requis';
          }
        });

      /** E-mail fields */
      fields
        .filter((field) => field.type === 'email' && !hasProperty(errors, field.name))
        .forEach(({ name }) => {
          if (!formData.get(name).match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            errors[name] = 'L’adresse e-mail est invalide';
          }
        });

      /** Fields with a minimum length */
      fields
        .filter((field) => field.minLength !== undefined && !hasProperty(errors, field.name))
        .forEach(({ name, minLength }) => {
          if (formData.get(name).length < minLength) {
            errors[name] = `La longueur minimale est de ${minLength} caractères`;
          }
        });

      /**
       * Password fields
       * Fields are filtered by name rather than by type because when the password is visible, the type of the field is "text"
       */
      fields
        .filter((field) => field.name.includes('password') && !hasProperty(errors, field.name))
        .forEach(({ name }) => {
          // prettier-ignore
          if (!formData.get(name).match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&*()\-_=+\\|[\]{};:'",.<>/?!£]).{12,}$/)) {
            errors[name] = 'Le format du mot de passe n’est pas correct';
          }
        });

      /** Fields that should match another */
      fields
        .filter((field) => field.shouldMatch !== undefined && !hasProperty(errors, field.name))
        .forEach(({ name, shouldMatch }) => {
          if (formData.get(name) !== formData.get(shouldMatch)) {
            errors[name] = 'La confirmation ne correspond pas';
          }
        });
      const isValid = Object.values(errors).filter((value) => value.length > 0).length === 0;
      return [isValid, errors];
    },
    [fields]
  );

  /** Return the hook callback function */
  return validateForm;
};

export { useFormValidation };
export default useFormValidation;
