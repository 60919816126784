import { useRef, useCallback, useMemo } from 'react';

/** Components */
import Form from './Form';

/** Data */
import { schoolsFlattened as schools } from '../../data/schools';

/**
 * <RegistrationForm />
 */

const RegistrationForm = (props) => {
  const formRef = useRef();

  const fields = useMemo(() => {
    /** Select a school from the url search params or from the document referrer */
    let referrerSchool;
    const params = new URL(document.location).searchParams;
    if (params.has('school')) {
      referrerSchool = schools.filter((school) => school.shortname === params.get('school'))[0];
    } else {
      referrerSchool = schools.filter((school) => school.referrer && school.referrer.includes(document.referrer))[0];
    }
    const selectedSchool = referrerSchool ? referrerSchool.name : '';

    return [
      {
        name: 'firstname',
        type: 'text',
        label: 'Prénom',
        placeholder: 'Saisissez votre prénom',
        description: '* Champ obligatoire',
        isRequired: true,
      },
      {
        name: 'lastname',
        type: 'text',
        label: 'Nom de famille',
        placeholder: 'Saisissez votre nom de famille',
        description: '* Champ obligatoire',
        isRequired: true,
      },
      {
        name: 'level',
        type: 'select',
        label: 'Niveau d’études',
        placeholder: 'Sélectionnez le niveau de vos études',
        description: '* Champ obligatoire',
        values: ['Seconde', 'Première', 'Terminale', 'BAC+1', 'BAC+2', 'BAC+3', 'BAC+4', 'BAC+5', 'Autres'],
        isRequired: true,
      },
      {
        id: 'school',
        name: 'school',
        type: 'select',
        label: 'École envisagée',
        placeholder: 'Sélectionnez votre école',
        description: '* Champ obligatoire',
        values: [...schools.map((school) => school.name), 'Je ne sais pas encore'],
        // logos: schools.map((school) => school.logo),
        value: selectedSchool,
        isRequired: true,
      },
      {
        name: 'mail',
        type: 'email',
        label: 'E-mail',
        placeholder: 'nom@email.com',
        description: '* Champ obligatoire',
        isRequired: true,
      },
      {
        name: 'password',
        type: 'password',
        minLength: 8,
        label: 'Mot de passe',
        placeholder: 'Créez un mot de passe',
        description: '12 caractères minimum dont 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère non alphanumérique',
        isRequired: true,
        hasShowOption: true,
      },
      {
        name: 'password_confirmation',
        type: 'password',
        minLength: 8,
        label: 'Confirmation du mot de passe',
        placeholder: 'Mot de passe',
        description: '* Champ obligatoire',
        isRequired: true,
        hasShowOption: true,
        shouldMatch: 'password',
      },
      {
        name: 'phone_suffix',
        type: 'tel',
        label: 'Téléphone',
        placeholder: '6 12 34 56 78',
        description: '* Champ obligatoire',
        isRequired: true,
        countrySelector: 'phone_prefix',
      },
      {
        name: 'newsletter',
        type: 'checkbox',
        label:
          'En cochant cette case, j’accepte que le groupe OMNES Education m’envoie des informations concernant ses offres de programmes.',
        value: 'subscribe',
      },
      {
        name: 'legal',
        type: 'checkbox',
        label:
          'J’ai lu et j’accepte les <a href="http://discovery-boostcamp.omneseducation.com/custom_omnes/BoostCampDiscovery-CGU.pdf" target="_blank" rel="noreferrer noopener">Conditions Générales d’Utilisation</a> et la <a href="http://discovery-boostcamp.omneseducation.com/custom_omnes/BoostcampDiscovery-Protections_des_données.pdf" target="_blank" rel="noreferrer noopener">Politique de Protection des Données Personnelles</a>.',
        description: '* Champ obligatoire',
        value: 'approved',
        isRequired: true,
      },
    ];
  }, []);

  /**
   * Submit the form, adding custom form fields
   *
   * @param {SubmitEvent} event  The form submit event
   */
  const onSubmit = useCallback((event) => {
    event.preventDefault();
    /** Populate the customFormData object of the form with the FormData from the DOM */
    const formData = new FormData(event.target);
    for (const [name, value] of formData) {
      formRef.current.setFormData(name, value);
    }
    /** Append the phone number to the form, concatening the prefix & suffix, and removing special chars */
    const phone = formData.get('phone_prefix') + formData.get('phone_suffix').replace(/[^0-9]/g, '');
    formRef.current.setFormData('phone', phone);
    /** Append the school shortname to the form */
    const targetSchool = schools.filter((school) => school.name === formData.get('school'))[0];
    formRef.current.setFormData('school_shortname', targetSchool ? targetSchool.shortname : null);
    /** Append real check newsletter */
    const newsletter = formData.get('newsletter') != null ? 1 : 0;
    formRef.current.setFormData('newsletter', newsletter);
    /** Append a "secret" to the form */
    process.env.REACT_APP_FORM_SECRET && formRef.current.setFormData('secret', process.env.REACT_APP_FORM_SECRET);
    /** Submit the form */
    formRef.current.submitForm(event);
  }, []);

  return (
    <Form
      ref={formRef}
      type="registration"
      action="create_user.php"
      autoComplete
      fields={fields}
      buttonLabel="Créer votre compte"
      label="Formulaire de création de compte"
      redirectMessage="Vous allez être redirigé vers la page de connexion dans %s secondes."
      onSubmit={(event) => onSubmit(event)}
    />
  );
};

export default RegistrationForm;
