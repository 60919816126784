import PropTypes from 'prop-types';

/*******
 * APP *
 *******/

/**
 * The colors of the app
 */
const colors = ['primary', 'secondary', 'third', 'grey', 'grey-light', 'grey-dark'];
export const colorPropTypes = PropTypes.oneOf(['white', ...colors]);
export const colorPropTypesNoWhite = PropTypes.oneOf(colors);

/**
 * The base props of a link
 */
export const linkPropTypes = {
  url: PropTypes.string.isRequired,
  target: PropTypes.string,
  title: PropTypes.string,
};
