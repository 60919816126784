/** Layouts */
import { Section, SectionTitle, SectionContent } from '../Section';
import Container from '../Container';

/** Components */
import Icon from '../../Icon';
import liveClasses from '../../../images/experience-live-classes.webp';
import funCourses from '../../../images/experience-fun-courses.webp';
import citizenEngagement from '../../../images/experience-citizen-engagement.webp';

/**
 * <Experience />
 */

const Experience = (props) => {
  return (
    <Section className="experience">
      <Container layout="narrow">
        <SectionTitle>Vivez l’expérience de moments pédagogiques inoubliables</SectionTitle>
        <SectionContent>
          <div>
            <p>
              Des <strong>cours Live</strong> interactifs et dynamiques
            </p>
          </div>
          <div>
            <img src={liveClasses} alt="Cours live interactifs" />
          </div>
        </SectionContent>
        <SectionContent>
          <div>
            <p>
              Des <strong>parcours ludiques</strong> dont vous êtes le héros
            </p>
            <Icon icon="gold-award" ariaLabel="Gold Award" />
            <hr />
            <blockquote>
              <p>“Pardon my french” Parcours d’onboarding des étudiants internationaux </p>
              <cite>
                Gold Award &quot;Excellence in Learning Design - International&quot;
                <br />
                Learning Technologies Awards
              </cite>
            </blockquote>
          </div>
          <div>
            <img src={funCourses} alt="Parcours ludiques" />
          </div>
        </SectionContent>
        <SectionContent>
          <div>
            <p>
              Un programme de missions bénévoles, pour <strong>développer votre engagement citoyen</strong>
            </p>
          </div>
          <div>
            <img src={citizenEngagement} alt="Engagement citoyen" />
          </div>
        </SectionContent>
      </Container>
    </Section>
  );
};

export default Experience;
