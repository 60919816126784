import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useCheckbox, useFocusRing } from 'react-aria';
import { useToggleState } from 'react-stately';

/** Components */
import { fieldPropTypes, Field } from './Field';

/**
 * <Checkbox />
 */

export const Checkbox = (props) => {
  const { description, errorMessage, isRequired, children } = props;

  const inputRef = useRef();
  const inputState = useToggleState(props);
  const { inputProps } = useCheckbox(props, inputState, inputRef);
  const { isFocusVisible, focusProps } = useFocusRing({ isTextInput: false });

  const isSelected = inputState.isSelected && !props.isIndeterminate;
  const isDisabled = inputState.isDisabled || props.isDisabled;

  return (
    <Field
      className="checkbox-field"
      description={description}
      errorMessage={errorMessage}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <label className={`checkbox-label ${isDisabled ? 'is-disabled' : ''}`}>
        <span className="visually-hidden">
          <input ref={inputRef} {...inputProps} {...focusProps} />
        </span>
        <span
          className={`checkbox ${isSelected ? 'is-selected' : ''} ${isFocusVisible ? 'is-focused' : ''}`}
          aria-hidden="true"
        />
        {typeof children === 'string' ? (
          <span className="label" dangerouslySetInnerHTML={{ __html: children }} />
        ) : (
          <span className="label">{children}</span>
        )}
      </label>
    </Field>
  );
};

Checkbox.propTypes = {
  ...fieldPropTypes,
  /** The value of the checkbox */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** Whether the checkbox is LGBTQI+ */
  isIndeterminate: PropTypes.bool,
  /** The label of the checkbox */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Checkbox;
