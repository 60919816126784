import { useRef, useState } from 'react';

/** Layouts */
import { Section, SectionTitle, SectionContent } from '../Section';
import Container from '../Container';

/** Components */
import Link from '../../Link';
import Button from '../../Button';
import Collapsable from '../../Collapsable';

/** Data */
import { schoolsGrouped as schools } from '../../../data/schools';

/**
 * <Schools />
 */

const Schools = (props) => {
  const collapsableRefs = useRef([]);
  const buttonRefs = useRef([]);
  const [hasRenderedButtons, setHasRenderedButtons] = useState(false);

  return (
    <Section className="schools">
      <Container layout="narrow">
        <SectionTitle>
          Découvrez la diversité et la richesse pédagogique de toutes les écoles du groupe Omnes Education
        </SectionTitle>
        <hr />
        <SectionContent>
          <nav className="schools-nav">
            <ul>
              {schools.map(({ category }, index) => (
                <li key={index}>
                  <Button
                    ref={(elt) => {
                      buttonRefs.current[index] = elt;
                      setHasRenderedButtons(index === schools.length - 1);
                    }}
                    onPress={() => {
                      collapsableRefs.current[index].isCollapsed() && collapsableRefs.current[index].toggle();
                      collapsableRefs.current.forEach((collapsable, collapsableIndex) => {
                        index !== collapsableIndex && !collapsable.isCollapsed() && collapsable.toggle();
                      });
                    }}
                  >
                    {category}
                  </Button>
                </li>
              ))}
            </ul>
          </nav>
          <div className="schools-articles">
            {hasRenderedButtons &&
              schools.map(({ category, description, schools }, index) => (
                <article key={index}>
                  <Collapsable
                    ref={(elt) => (collapsableRefs.current[index] = elt)}
                    buttonRef={{ current: buttonRefs.current[index] }}
                    isCollapsed={index > 0}
                  >
                    <h3 className="visually-hidden">{category}</h3>
                    <p>{description}</p>
                    <ul className="schools-list">
                      {schools
                        .filter((school) => school.logo)
                        .map(({ name, url, logo }, index) => (
                          <li key={index}>
                            {url ? (
                              <Link url={url} target="_blank">
                                <img src={logo} alt={name} />
                              </Link>
                            ) : (
                              <span className="link">
                                <img src={logo} alt={name} />
                              </span>
                            )}
                          </li>
                        ))}
                    </ul>
                  </Collapsable>
                </article>
              ))}
          </div>
        </SectionContent>
      </Container>
    </Section>
  );
};

export default Schools;
