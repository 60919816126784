/** Layouts */
import Container from './Container';

/** Components */
import Icon from '../Icon';
import Link from '../Link';

const links = [
  [
    {
      title: 'Mentions légales',
      url: 'https://www.omneseducation.com/mentions-legales-donnees-personnelles/',
    },
    {
      title: 'Politique de confidentialité',
      url: 'https://www.omneseducation.com/mentions-legales-donnees-personnelles/#politique-confidentialite',
    },
    {
      title: 'Politique de cookies',
      url: 'https://www.omneseducation.com/mentions-legales-donnees-personnelles/#cookies',
    },
    {
      title: 'Nous contacter',
      url: 'mailto:boostcamp.discovery@omneseducation.com?subject=Demande de renseignements',
    },
    {
      title: 'Presse',
      url: 'https://www.omneseducation.com/page-presse/',
    },
  ],
  [
    {
      title: 'Conditions générales d’inscription',
      url: 'https://www.omneseducation.com/conditions-generales-dinscription/',
    },
    {
      title: 'Conditions générales d’utilisation',
      url: 'https://www.omneseducation.com/conditions-generales-dutilisation/',
    },
    {
      title: 'FAQ : Toutes les réponses à vos questions',
      url: 'https://www.omneseducation.com/orientation/faq/',
    },
    {
      title: 'EU Business School Barcelone',
      url: 'https://www.euruni.edu/',
    },
  ],
  [
    {
      title: 'YouTube',
      url: 'https://www.youtube.com/OMNESEducation',
      icon: 'y',
    },
    {
      title: 'Facebook',
      url: 'https://www.facebook.com/omneseducationgroup/',
      icon: 'f',
    },
    {
      title: 'X (Twitter)',
      url: 'https://twitter.com/omneseducation_',
      icon: 'x',
    },
    {
      title: 'LinkedIn',
      url: 'https://www.linkedin.com/school/omneseducation/',
      icon: 'l',
    },
    {
      title: 'Instagram',
      url: 'https://www.instagram.com/omneseducationgroup/',
      icon: 'i',
    },
  ],
];

/**
 * <Footer />
 */

const Footer = (props) => {
  return (
    <footer className="layout footer bg-third">
      <Container>
        <h2 className="visually-hidden">Liens utiles</h2>
        <div className="footer-section">
          {links.map((section, index) => (
            <ul key={index} className="footer-links">
              {section.map(({ title, url, icon }, index) => (
                <li key={index}>
                  <Link url={url} target={url.includes('mailto:') ? '_self' : '_blank'}>
                    {icon && <Icon icon={icon} layout="square-outline" ariaLabel={title} />}
                    <span className={icon ? 'visually-hidden' : ''}>{title}</span>
                  </Link>
                </li>
              ))}
            </ul>
          ))}
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
