import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useCountUp } from 'react-countup';

/** PropTypes */
import { colorPropTypesNoWhite } from '../propTypes';

/**
 * <CountUp />
 */

const CountUp = ({ number, text, duration, color }) => {
  /** Count up props */
  const countUpRef = useRef();
  const { start } = useCountUp({
    ref: countUpRef,
    startOnMount: false,
    start: 1,
    end: number,
    duration,
    separator: ' ',
  });

  /** Intersection observer */
  useEffect(() => {
    const options = {
      rootMargin: '2%',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting && entry.intersectionRatio >= options.threshold) {
        start();
        observer.unobserve(countUpRef.current);
      }
    }, options);
    observer.observe(countUpRef.current);
  }, [start]);

  /** Class names */
  const classNames = ['count-up'];
  color && classNames.push(`c-${color}`);

  return (
    <>
      <div className={classNames.join(' ')}>
        <span ref={countUpRef} className="count-up-number" />
        <span className="count-up-text">{text}</span>
      </div>
    </>
  );
};

CountUp.propTypes = {
  /** The number to display */
  number: PropTypes.number.isRequired,
  /** The text to display */
  text: PropTypes.string.isRequired,
  /** The duration of the animation */
  duration: PropTypes.number,
  /** The color of the outline */
  color: colorPropTypesNoWhite,
};
CountUp.defaultProps = {
  duration: 2.5,
};

export default CountUp;
