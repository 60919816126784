import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const commonPropTypes = {
  /** The class names to add to the element */
  className: PropTypes.string,
  /** The children of the element */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
const commonDefaultProps = {
  className: '',
};

/**
 * <Section />
 */

export const Section = forwardRef(({ id, className, children, style, tag: Tag }, sectionRef) => (
  <Tag ref={sectionRef} id={id} className={`section ${className}`} style={style}>
    {children}
  </Tag>
));

Section.displayName = 'Section';
Section.propTypes = {
  ...commonPropTypes,
  /** The id attribute of the element */
  id: PropTypes.string,
  /** The css styles to apply to the element */
  style: PropTypes.object,
  /** The tag name to use for the element */
  tag: PropTypes.oneOf(['section', 'header', 'div']),
};
Section.defaultProps = {
  ...commonDefaultProps,
  style: {},
  tag: 'section',
};

/**
 * <SectionTitle />
 */

export const SectionTitle = ({ className, children }) => <h2 className={`section-title ${className}`}>{children}</h2>;

SectionTitle.propTypes = commonPropTypes;
SectionTitle.defaultProps = commonDefaultProps;

/**
 * <SectionContent />
 */

export const SectionContent = forwardRef(({ className, children }, contentRef) => (
  <div ref={contentRef} className={`section-content ${className}`}>
    {children}
  </div>
));

SectionContent.displayName = 'SectionContent';
SectionContent.propTypes = commonPropTypes;
SectionContent.defaultProps = commonDefaultProps;

export default Section;
