import PropTypes from 'prop-types';

/**
 * <Covers />
 */

const Covers = ({ className }) => {
  return (
    <div className={`covers ${className}`}>
      {[...Array(16).keys()].map((value, index) => (
        <span key={index} />
      ))}
    </div>
  );
};

Covers.propTypes = {
  className: PropTypes.string,
};
Covers.defaultProps = {
  className: '',
};

export default Covers;
