/** Utils */
import { getAbsolutePath } from '../../../util/File';

/** Layouts */
import { Section, SectionTitle, SectionContent } from '../Section';
import Container from '../Container';

/** Components */
import Icon from '../../Icon';
import Course1 from '../../../images/course-1.webp';
import Course2 from '../../../images/course-2.webp';
import Course3 from '../../../images/course-3.webp';
import Course4 from '../../../images/course-4.webp';
import Course5 from '../../../images/course-5.webp';
import Course6 from '../../../images/course-6.webp';
import Course7 from '../../../images/course-7.webp';
import Course8 from '../../../images/course-8.webp';

const courses = [
  {
    title: 'Boostez vos compétences mathématiques',
    lessons: 4,
    duration: '8h',
    isFeatured: true,
    image: Course1,
  },
  {
    title: 'Footbolitique',
    lessons: 9,
    duration: '6h',
    isFeatured: true,
    image: Course2,
  },
  {
    title: 'Apprendre à l’ère de l’IA',
    lessons: 1,
    duration: '45 min',
    isFeatured: true,
    image: Course3,
  },
  {
    title: 'Do you speak TV show',
    lessons: 5,
    duration: '10h30',
    isFeatured: true,
    image: Course4,
  },
  {
    title: 'Humanités',
    lessons: 18,
    duration: '9h',
    isFeatured: true,
    image: Course5,
  },
  {
    title: 'Ethics and IA',
    lessons: 4,
    duration: '6h',
    isFeatured: true,
    image: Course6,
  },
  {
    title: 'Finance à impact',
    lessons: 4,
    duration: '13h',
    isFeatured: true,
    image: Course7,
  },
  {
    title: 'Management et Leadership',
    lessons: 7,
    duration: '30h',
    isFeatured: true,
    image: Course8,
  },
];

/**
 * <Catalog />
 */

const Catalog = (props) => {
  return (
    <Section className="catalog">
      <Container layout="narrow">
        <SectionTitle>
          En vous inscrivant sur Boostcamp Discovery
          <br />
          accédez <strong>gratuitement</strong> à 8 cours
        </SectionTitle>
        <SectionContent>
          <p>Issus de notre catalogue de 170 cours provenant de toutes nos écoles.</p>
        </SectionContent>
        <SectionContent className="courses">
          {courses.map(({ title, lessons, duration, isFeatured, image }, index) => (
            <article
              key={index}
              className="article courses-article bg-grey"
              style={{
                '--background-image': `url('${getAbsolutePath(image)}')`,
              }}
            >
              <div>
                <h3 className="h4">{title}</h3>
                <ul>
                  <li>
                    <Icon icon="layers" />
                    {` ${lessons} épisode${lessons > 1 ? 's' : ''}`}
                  </li>
                  <li>
                    <Icon icon="clock" />
                    {` ${duration}`}
                  </li>
                  {isFeatured && (
                    <li className="featured">
                      <Icon icon="star" /> Populaire
                    </li>
                  )}
                </ul>
              </div>
            </article>
          ))}
        </SectionContent>
      </Container>
    </Section>
  );
};

export default Catalog;
